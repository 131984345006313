import store from '@/store'
import {debounce} from 'lodash'
/**
 * Storeから取得してきたデータのうちKeysに含まれるものをsetterつきのcomputedデータとして扱えるよう変換する
 * @param {Array} keys
 */
export function mapComputedProperties(keys) {
    const map = []
    keys.forEach(key => {
        map[key] = {
            get(){
                return this.data[key]
            },
            set(newValue){
                this.update({
                    [key]:newValue
                })
            }
        }
    })
    return map
}

export function mapWatchers(keys) {
    const map = []
    keys.forEach(key => {
        Object.keys(store.state[key]).forEach(childKey => {
            map[key + '.' + childKey] = debounce(newValue => {
                store.dispatch('updateObject', {
                    [key]:{
                        [childKey]:newValue
                    }
                })
                if (key == 'coeff') {
                    store.dispatch('calc')
                }
            }, 100)
        })
    })
    return map
}

// 文字表示
export function print(str){
	console.log(str)
}

/**
 * エラーの発生している月を配列形式で返す
 */
export function getErrorMonth(dataArray, calorificValueSelect, cons = null){
    let errorMonth = []

    let check = (i, condition) => {
        if (condition) {
			errorMonth.push((i+1)+'月')
        }

    }

    dataArray.forEach((value, index) => {
        if (cons != null) {
            check(index, value > cons[index])
        } else {
            check(index, value[0] && value[1])
        }
    })

    return errorMonth
}

// floatの配列を整数に変換
export function arrayParseInt(arr){
	if( Array.isArray(arr) ){
		return arr.map(e => arrayParseInt(e))
	} else {
		return parseInt(arr)
	}
}

// floatの配列を整数に切り捨てして文字列化
export function array2StringFloor(arr){
	return arrayParseInt(arr).toString()
}
