import { getErrorMonth } from './util.js'
import { sum } from 'mathjs'


/**
 * 2.1 基本的な関数・定数
 */

// 1) 月の名前を返す関数
const MONTH_NAMES = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']

// 2) Bool値の配列（長さ=12）を受け取りTrueの月を文字列で返す
export function getTrueMonth(boolArray){
    if( boolArray.length != 12 ){
        throw new Error('ERROR: Bool値の配列長が12ではありません。')
    }
    const stringMonths = MONTH_NAMES.filter( (e, i) => boolArray[i] )
    //stringMonths = [mn for (i, mn) in enumerate(MONTH_NAMES) if boolArray[i]]
    return stringMonths.join(', ')
}

// 暖冷房設備以外の用途の参照一次エネルギー消費量が暖冷房設備以外の用途の参照一次エネルギー消費量の年間合計値に占める割合
export function getRatio(E_exHC_ref){
    // vs : value, ndarray, length = 12

    const sum_of_E_exHC_ref = sum(E_exHC_ref)
    return sum_of_E_exHC_ref > 0.0 ? E_exHC_ref.map(E_exHC_ref_m => E_exHC_ref_m / sum_of_E_exHC_ref) : new Array(12).fill(0)
}

// 4) 1月～12月までの値を辞書型でもらって表にする関数
export function showMonthlyTable(dictionary){
    // d: 辞書型 定義の例 → {'ventilation': [1,2,3,4,5,6,7,8,9,10,11,12]}  配列の長さは必ず12にすること
    //return pd.DataFrame(d, index = MONTH_NAMES).T
    const data = Object.keys(dictionary).reduce((object, key) => Object.assign(object, {
        [key]: dictionary[key].reduce((object, item, i) => Object.assign(object, {[MONTH_NAMES[i]]: item}), {})
    }), {})
    return data
}


/**
 * 2.2 中間月（暖房月・冷房月ではない月）の取得
 *
 * i. 暖房月と冷房月の長さの確認
 *
 * 12の配列になっているのかを確認します。
 *
 * ii. 同じ月に暖房使用月と冷房使用月が同時に発生していないことの確認
 *
 * 同じ月に暖房使用月と冷房使用月が同時に発生した場合、用途分解が不可能なため、エラーとしてはじくことにします。
 *
 * iii. 中間期の取得
 *
 * 暖房月でも冷房月でもない月を中間月とします。
 */
export function getIM_m(heatingUse, coolingUse, calorificValueSelect){
    if( heatingUse.length != 12 ){
        throw new Error('ERROR: 暖房の使用の有無が12ヶ月分ありません。')
    }

    if( coolingUse.length != 12 ){
        throw new Error('ERROR: 冷房の使用の有無が12ヶ月分ありません。')
    }

    const zipped = heatingUse.map((h, i) => [h, coolingUse[i]])

    const errorMonth = getErrorMonth(zipped, calorificValueSelect)

    if(errorMonth.length !== 0){
        throw new Error('エラー：同じ月に暖房使用月と冷房使用月は設定できません。(' + errorMonth + ')' )
    }

    return zipped.map(e => !e[0] && !e[1])
}


/**
 * 5.1 換気設備の参照エネルギー消費量
 */
export function getStdConsumptionForVentilation(numberOfPeople, A_A, C_V){
    // numberOfPeople : number of people (person)
    // A_A : 床面積の合計, m2
    // C_V : 換気設備の調整係数

    // 表2a 係数α_(V,m) MJ/m2
    const coeff_alpha_V_m = [2.87553024, 2.59725312, 2.87553024, 2.7827712, 2.87553024, 2.7827712, 2.87553024, 2.87553024, 2.7827712, 2.87553024, 2.7827712, 2.87553024]

    // 表2b 係数 𝛽𝑉, 𝑚 MJ
    const coeff_beta_V_m = {
        1: [11.0890192, 9.9743296, 11.0890192, 10.5689088, 10.7698672, 10.6953984, 11.155192, 11.0559328, 10.5689088, 10.9294432, 10.7615712, 10.9294432],
        2: [22.1551024, 19.9277728, 22.1551024, 21.1146864, 21.5150416, 21.368544, 22.287448, 22.0889296, 21.1146864, 21.835072, 21.5008896, 21.835072],
        3: [33.2506608, 29.9079584, 33.2506608, 31.6898416, 32.2912528, 32.0701888, 33.4493744, 33.151304, 31.6898416, 32.7709568, 32.2689024, 32.7709568],
        4: [44.3076672, 39.8532032, 44.3076672, 42.2268352, 43.0273504, 42.7345504, 44.5725536, 44.175224, 42.2268352, 43.6675088, 42.9994368, 43.6675088],
    }[numberOfPeople]

    return coeff_alpha_V_m.map(function(alpha_V_m, i) { return (alpha_V_m * A_A + coeff_beta_V_m[i]) * C_V})
}


/**
 * 5.2 照明設備の参照エネルギー消費量
 */
export function getStdConsumptionForLighting(numberOfPeople, A_MR, A_OR, A_A, C_L){
    // numberOfPeople : number of people, person
    // A_MR : 主たる居室の床面積, m2
    // A_OR : その他の居室の床面積, m2
    // A_A : 床面積の合計, m2
    // C_L : 照明設備の調整係数

    // 表 3a 係数𝛼𝐿,𝑚
    const coeff_alpha_L_m = {
        1: [4.412, 3.916, 4.412, 4.328, 4.429, 4.102, 4.846, 4.194, 4.328, 4.42, 4.536, 4.42],
        2: [9.194, 8.256, 9.194, 8.741, 8.878, 8.845, 9.302, 9.139, 8.741, 9.036, 8.953, 9.036],
        3: [10.262, 9.219, 10.262, 9.737, 9.881, 9.888, 10.339, 10.223, 9.737, 10.071, 9.966, 10.071],
        4: [11.33, 10.182, 11.33, 10.732, 10.884, 10.932, 11.376, 11.306, 10.732, 11.107, 10.978, 11.107]
    }[numberOfPeople]

    // 表 3b 係数𝛽𝐿,𝑚
    const coeff_beta_L_m = {
        1: [0.229, 0.206, 0.229, 0.226, 0.235, 0.219, 0.235, 0.226, 0.226, 0.232, 0.226, 0.232],
        2: [0.471, 0.426, 0.471, 0.451, 0.464, 0.458, 0.464, 0.474, 0.451, 0.468, 0.451, 0.468],
        3: [2.216, 1.98, 2.216, 2.131, 2.169, 2.102, 2.321, 2.163, 2.131, 2.192, 2.207, 2.192],
        4: [3.961, 3.534, 3.961, 3.811, 3.873, 3.746, 4.178, 3.852, 3.811, 3.917, 3.964, 3.917]
    }[numberOfPeople]

    // 表 3c 係数𝛾𝐿,𝑚
    const coeff_gamma_L_m = {
        1: [0.925, 0.833, 0.925, 0.92, 0.964, 0.882, 0.964, 0.906, 0.92, 0.945, 0.92, 0.945],
        2: [2.219, 2.006, 2.219, 2.129, 2.192, 2.158, 2.188, 2.234, 2.129, 2.205, 2.127, 2.205],
        3: [2.9, 2.613, 2.9, 2.785, 2.857, 2.799, 2.917, 2.892, 2.785, 2.878, 2.815, 2.878],
        4: [3.582, 3.219, 3.582, 3.441, 3.522, 3.44, 3.646, 3.55, 3.441, 3.552, 3.503, 3.552]
    }[numberOfPeople]

    // 表 3d 係数𝛿𝐿,𝑚
    const coeff_delta_L_m = {
        1: [54.52, 48.65, 54.52, 53.82, 55.58, 51.11, 58.89, 52.34, 53.82, 55.05, 55.47, 55.0],
        2: [121.51, 109.4, 121.51, 116.08, 118.58, 117.32, 121.98, 121.28, 116.08, 120.04, 117.78, 120.04],
        3: [172.18, 154.65, 172.18, 164.4, 167.46, 165.48, 174.75, 170.9, 164.4, 169.82, 168.05, 169.82],
        4: [222.85, 199.9, 222.85, 212.72, 216.34, 213.65, 227.51, 220.52, 212.72, 219.59, 218.31, 219.59]
    }[numberOfPeople]

    // 非居室の床面積, m2
    const A_NR = (A_A - A_MR - A_OR)

    return coeff_alpha_L_m.map(function(alpha_L_m, i) { return (alpha_L_m * A_MR + coeff_beta_L_m[i] * A_OR + coeff_gamma_L_m[i] * A_NR + coeff_delta_L_m[i]) * C_L })
}

/**
 * 5.3 給湯設備の参照エネルギー消費量
 */
export function getStdConsumptionForHotWater(numberOfPeople, region, C_HW ){
    // numberOfPeople : number of people, person
    // region : 地域の区分
    // C_HW : 調整係数

    // 表4 給湯の一次エネルギー消費量
    const referencePrimaryEnergyConsumptionForHotWaterSupply = {
        1: {
            1: [1277.1, 1139.1, 1251.8, 1027.9, 924.3, 819.9, 815.6, 706.7, 778.9, 943.5, 1064.5, 1199.9],
            2: [1261.8, 1130.5, 1247.1, 1004.4, 908.2, 826.0, 787.3, 687.9, 739.5, 906.5, 1040.6, 1162.5],
            3: [1172.1, 1051.0, 1115.4, 940.5, 834.7, 759.6, 752.8, 662.6, 699.4, 861.3, 979.9, 1073.8],
            4: [1138.0, 1134.3, 1110.2, 915.0, 799.6, 726.0, 724.9, 624.4, 680.9, 827.6, 958.3, 1047.6],
            5: [1213.6, 1096.0, 1154.8, 908.7, 755.0, 681.3, 639.5, 549.2, 591.8, 789.7, 954.1, 1135.7],
            6: [1129.8, 1006.9, 1080.2, 829.8, 709.6, 582.4, 524.3, 449.6, 532.7, 700.8, 871.8, 1008.1],
            7: [1024.0, 901.6, 958.5, 732.3, 638.5, 545.3, 465.6, 423.5, 459.8, 636.1, 814.7, 919.0],
            8: [699.5, 636.5, 696.8, 585.7, 522.3, 487.1, 459.7, 431.7, 435.9, 496.5, 585.5, 644.1]
        },
        2: {
            1: [2049.2, 1826.3, 1999.1, 1628.5, 1460.5, 1287.9, 1281.9, 1103, 1230.4, 1496.4, 1697.9, 1918.4],
            2: [2014.5, 1803.2, 1987.7, 1592.6, 1435.8, 1296.3, 1231.8, 1070.7, 1167.7, 1435.4, 1657.8, 1854.2],
            3: [1866.8, 1674.0, 1775.7, 1490.4, 1318.4, 1188.1, 1175.1, 1027.1, 1098.9, 1364.3, 1560.6, 1714.0],
            4: [1811.0, 1646.9, 1767.0, 1445.7, 1260.1, 1132.9, 1131.1, 966.6, 1070.1, 1309.3, 1524.7, 1669.6],
            5: [1902.2, 1719.1, 1812.5, 1429.0, 1188.1, 1069.5, 1004.5, 863.3, 934.4, 1246.6, 1505.3, 1785.7],
            6: [1772.9, 1581.8, 1697.6, 1305.3, 1116.5, 914.2, 825.6, 710.2, 841.2, 1108.3, 1376.8, 1591.1],
            7: [1610.9, 1421.5, 1510.2, 1155.0, 1008.6, 859.0, 738.8, 671.7, 731.6, 1009.7, 1288.5, 1451.4],
            8: [1106.7, 1008.0, 1104.4, 925.7, 826.3, 767.0, 726.9, 682.0, 690.1, 786.5, 928.0, 1021.9]
        },
        3: {
            1: [3031.3, 2709.7, 2932.9, 2425.6, 2194.4, 1932.9, 1880.4, 1655.4, 1838.5, 2232.5, 2512.4, 2855.1],
            2: [2978.6, 2674.9, 2916.4, 2374.1, 2157.9, 1942.3, 1810.4, 1608.9, 1746.6, 2142.2, 2452.3, 2762.2],
            3: [2764.8, 2486.5, 2610.9, 2224.1, 1985.8, 1783.0, 1728.0, 1540.9, 1645.1, 2037.0, 2311.4, 2556.5],
            4: [2685.6, 2448.4, 2596.2, 2158.0, 1897.6, 1700.7, 1660.6, 1449.2, 1600.3, 1957.4, 2257.5, 2490.1],
            5: [2853.4, 2584.8, 2696.1, 2153.6, 1806.8, 1620.1, 1488.6, 1305.6, 1411.7, 1883.6, 2253.3, 2691.7],
            6: [2660.1, 2378.9, 2526.9, 1969.0, 1700.1, 1386.2, 1222.9, 1073.8, 1273.7, 1678.5, 2061.5, 2403.1],
            7: [2417.2, 2138.7, 2248.7, 1747.0, 1538.1, 1303.8, 1093.0, 1018.4, 1108.0, 1529.1, 1930.1, 2196.1],
            8: [1668.1, 1522.5, 1648.1, 1400.8, 1258.8, 1159.7, 1076.6, 1031.0, 1041.1, 1189.9, 1395.2, 1550.2]
        },
        4: {
            1: [3377.2, 3021.8, 3267.5, 2710.3, 2456.3, 2163.7, 2097.3, 1854.1, 2056.9, 2496.7, 2803.2, 3187.1],
            2: [3319.7, 2983.9, 3249.4, 2653.7, 2415.6, 2173.8, 2018.7, 1801.5, 1954.3, 2395.7, 2736.7, 3084.8],
            3: [3084.6, 2776.0, 2912.6, 2486.9, 2224.0, 1996.0, 1926.5, 1727.2, 1841.0, 2278.5, 2580.8, 2857.6],
            4: [2997.5, 2734.1, 2896.0, 2412.8, 2125.0, 1904.1, 1853.3, 1626.4, 1791.4, 2190.0, 2520.8, 2784.2],
            5: [3211.2, 2910.5, 3031.3, 2425.0, 2037.1, 1825.2, 1671.2, 1473.9, 1589.9, 2122.0, 2533.8, 3033.1],
            6: [2994.4, 2679.2, 2841.1, 2217.1, 1916.9, 1561.0, 1376.9, 1215.4, 1434.7, 1891.2, 2317.5, 2708.8],
            7: [2720.5, 2408.5, 2528.0, 1967.8, 1734.9, 1469.3, 1231.4, 1153.1, 1250.3, 1723.0, 2169.6, 2475.9],
            8: [1877.3, 1714.4, 1852.7, 1577.1, 1421.2, 1309.9, 1213.4, 1167.3, 1177.8, 1343.4, 1568.2, 1747.1]
        }

    }[numberOfPeople][region]

    return referencePrimaryEnergyConsumptionForHotWaterSupply.map(e => e * C_HW )
}

/**
 * 5.4 家電の参照エネルギー消費量
 */
export function getStdConsumptionForElectricAppliance(numberOfPeople, C_AP){
    // numberOfPeople : number of people, person
    // C_AP : 家電調整係数

    // 表5 家電の参照一次エネルギー消費量
    const referencePrimaryEnergyConsumptionforHomeAppliances = {
        1: [849.7, 765.3, 849.7, 815.6, 836.1, 819.9, 854.6, 847.2, 815.6, 842.9, 824.9, 842.9],
        2: [1031.2, 928.7, 1031.2, 988.7, 1012.5, 995.1, 1037.2, 1028.3, 988.7, 1021.9, 1001.0, 1021.9],
        3: [1409.4, 1268.9, 1409.4, 1352.0, 1384.8, 1359.1, 1419.7, 1404.2, 1352.0, 1397.1, 1369.5, 1397.1],
        4: [1481.9, 1333.7, 1481.9, 1424.0, 1459.5, 1427.1, 1498.0, 1473.8, 1424.0, 1470.7, 1443.2, 1470.7]
    }[numberOfPeople]

    return referencePrimaryEnergyConsumptionforHomeAppliances.map(e => e * C_AP)
}


/**
 * 5.5 調理の参照一次エネルギー消費量
 */
export function getStdConsumptionForCooking(numberOfPeople, C_CC){
    // p : number of people, person
    // C_CC : coefficient for cooking

    // 表6 調理の参照一次エネルギー消費量
    const referencePrimaryEnergyConsumptionforCooking = {
        1: [191, 171.1, 191, 179.5, 180.6, 183.6, 193.1, 189.9, 179.5, 185.8, 185.8, 185.8],
        2: [235.3, 211.3, 235.3, 220.1, 221.2, 227.6, 234.3, 235.7, 220.1, 228.2, 226.6, 228.2],
        3: [284, 255.1, 284, 265.7, 267, 274.7, 282.8, 284.6, 265.7, 275.5, 273.6, 275.5],
        4: [332.7, 298.8, 332.7, 311.2, 312.8, 321.9, 331.4, 333.4, 311.2, 322.8, 320.5, 322.8]
    }[numberOfPeople]

    return referencePrimaryEnergyConsumptionforCooking.map(e => e * C_CC)
}


/**
 * 暖冷房以外の用途の参照エネルギー消費量
 *
 *  表 1 エネルギー種別と単位及び用途
 *      項目        単位                        用途
 *                         暖房    冷房    換気    給湯    照明    家電    調理
 *      消費電力    kWh     〇      〇      〇      〇      〇      〇     〇
 *      ガス消費量  m3      〇                      〇                     〇
 *      灯油消費量  L       〇                      〇
 */

export function getStdConsumption(numberOfPeople, A_MR,
            A_OR, A_A,
            C_V, C_L, C_HW, C_AP, C_CC,
            ventilationUse, lightingUse, hotwaterUse, electricApplianceUse, cookingUse, calorificValueSelect, regoin =6){
    // numberOfPeople : number of people, person
    // A_MR : 主たる居室の床面積, m2
    // A_OR : その他の居室の床面積, m2
    // A_A : 床面積の合計, m2
    // C_V, C_L, C_HW, C_AP, C_CC
    //     : coefficient for ventilation, lighting, electric appliances, cooking
    // ventilationUse, lightingUse, hotwaterUse, electricApplianceUse, cookingUse
    //     : use for ventilation, lighting, hotwater, electricAppliance, cooking
    // for ventilation
    let E_V_ref_m
    if(ventilationUse && calorificValueSelect === '電気'){
        E_V_ref_m = getStdConsumptionForVentilation(numberOfPeople, A_A, C_V)
    } else {
        E_V_ref_m = new Array(12).fill(0)
    }

    // for lighting
    let E_L_ref_m
    if(lightingUse && calorificValueSelect === '電気'){
        E_L_ref_m = getStdConsumptionForLighting(numberOfPeople, A_MR, A_OR, A_A, C_L)
    } else {
        E_L_ref_m = new Array(12).fill(0)
    }

    // for hotwater
    let E_HW_ref_m
    if (hotwaterUse){
        E_HW_ref_m = getStdConsumptionForHotWater(numberOfPeople, regoin, C_HW)
    } else {
        E_HW_ref_m = new Array(12).fill(0)
    }

    // for electric appliances
    let E_AP_ref_m
    if( electricApplianceUse && calorificValueSelect === '電気' ){
        E_AP_ref_m = getStdConsumptionForElectricAppliance(numberOfPeople, C_AP)
    } else {
        E_AP_ref_m = new Array(12).fill(0)
    }

    // for cooking
    let E_CC_ref_m
    if( cookingUse && calorificValueSelect !== '灯油' ){
        E_CC_ref_m = getStdConsumptionForCooking(numberOfPeople, C_CC)
    } else {
        E_CC_ref_m = new Array(12).fill(0)
    }

    const E_exHC_ref_m = E_V_ref_m.map(
        (singleVentilation, index) =>
        singleVentilation + E_L_ref_m[index] + E_HW_ref_m[index] + E_AP_ref_m[index] + E_CC_ref_m[index]
    )

    return [E_V_ref_m, E_L_ref_m, E_AP_ref_m, E_CC_ref_m, E_HW_ref_m, E_exHC_ref_m]
}

/**
 * 暖冷房設備以外の用途の消費量
 */
export function getMonthlyValue(r_s_exHC_ref_m, IM_m, S_m){
    // r_s_exHC_ref_m : 月mにおける暖冷房設備以外の用途の参照消費量が暖冷房設備以外の用途の参照消費量の年間合計値に占める割合
    // IM_m : 月mが中間月か否か, bool 値
    // S_m : 月mにおける月別消費量

    let sumCons
    let sumRatio

    if( IM_m.includes(true) ){  // 中間期月がある場合、中間期月の割合とエネルギーを合計する
        sumCons = S_m.reduce((total, currentValue, i) => total + (IM_m[i] ? currentValue : 0.0), 0.0)  //消費量を合算
        sumRatio = r_s_exHC_ref_m.reduce((total, currentValue, i) => total + (IM_m[i] ? currentValue : 0.0), 0.0)  //給湯消費割合を合算
    } else {  // 中間期月がない場合、cons の最小値を採用し、その月のratioを採用する
        sumCons = Math.min(...S_m)
        sumRatio = r_s_exHC_ref_m[S_m.indexOf(sumCons)]
    }

    const S_exHC = (sumRatio == 0.0 ? 0.0 : sumCons / sumRatio)

    const getMonthly = (IM_m, S_m, r_s_exHC_ref_m, S_exHC) => {
        if( IM_m ){
            return S_m
        } else {
            return Math.min(S_m, S_exHC * r_s_exHC_ref_m)
        }
    }

    return IM_m.map((m, i) => getMonthly(m, S_m[i], r_s_exHC_ref_m[i], S_exHC))
}

/**
 * 月別の(灯油/ガス/電気)の一次エネルギー消費量
 */
export function getAllUse(heatingUse, coolingUse, f_PE, S_m, S_exHC_m,
    E_V_ref_m, E_L_ref_m, E_AP_ref_m, E_CC_ref_m, E_HW_ref_m, E_exHC_ref_m){

    const heating = heatingUse.map((u, i) => (u ? (S_m[i] - S_exHC_m[i]) * f_PE : 0.0))
    const cooling = coolingUse.map((u, i) => (u ? (S_m[i] - S_exHC_m[i]) * f_PE : 0.0))
    const ventilation = E_V_ref_m.map((value, i) => (E_exHC_ref_m[i] == 0 ? 0.0 : (S_exHC_m[i] * value / E_exHC_ref_m[i]) * f_PE))
    const lighting = E_L_ref_m.map((value, i) => (E_exHC_ref_m[i] == 0 ? 0.0 : (S_exHC_m[i] * value / E_exHC_ref_m[i]) * f_PE))
    const hotWater = E_HW_ref_m.map((value, i) => (E_exHC_ref_m[i] == 0 ? 0.0 : (S_exHC_m[i] * value / E_exHC_ref_m[i]) * f_PE))
    const electricAppliance = E_AP_ref_m.map((value, i) => (E_exHC_ref_m[i] == 0 ? 0.0 : (S_exHC_m[i] * value / E_exHC_ref_m[i]) * f_PE))
    const cooking = E_CC_ref_m.map((value, i) => (E_exHC_ref_m[i] == 0 ? 0.0 : (S_exHC_m[i] * value / E_exHC_ref_m[i]) * f_PE))

    return [heating, cooling, ventilation, lighting, hotWater, electricAppliance, cooking]
}