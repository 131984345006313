import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import('@/views/AppIndex.vue')
    },
    {
      path: '/form',
      name: 'form',
      component: () => import('@/views/AppForm.vue')
    },
    {
        path: '/result',
        name: 'result',
        component: () => import('@/views/Result.vue'),
        beforeEnter: (to, from, next) => {
            if (from.path !== '/form') {
                next({ path: '/form' })
            }
            else next()
          }
      }
  ]
})
