import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line
import Chart from 'chart.js'
import * as calculate from './calculate'
import { sum } from 'mathjs'

Vue.use(Vuex)

const state = {
  errors:'',
  numberOfPeople:'1',
  A_MR:29.81,
  A_OR:54.34,
  A_A:120.08,
  // エネルギー源チェックボックスの選択された値
  calorificValueToAnalyze: [],
  // 解析リスト
  analysisList: [],
  calorificValueList:{
    '電気':9.76,
    '都市ガス':45,
    '灯油':37,
    'LPG':100,
  },
  checkboxes: ['ventilation', 'hotWaterSupply', 'lighting', 'electricAppliance', 'cooking'],
  labelNames: {
    ventilation: '換気',
    hotWaterSupply: '給湯',
    lighting: '照明',
    electricAppliance: '家電',
    cooking: '調理'
  },
}

const getters = {
  data(state){
    return state
  }
}

const actions = {
    /**
     * Storeの各値の更新
     * @param {Object} values 更新対象のキーと値の組
     */
    update({commit}, values){
        commit('update', values)
    },
    updateObject({commit}, values) {
      commit('updateObject', values)
    },
    calc({state}){
        try {
            state.errors = ''
            state.analysisList.forEach(elem => {
                startAnalysis({
                    numberOfPeople: state.numberOfPeople,
                    A_MR: state.A_MR,
                    A_OR: state.A_OR,
                    A_A: state.A_A,
                    calorificValueList: state.calorificValueList
                }, elem)
            })
        } catch (e) {
            state.errors = e.message
            window.scroll(0,0)
        }
    },
    showResult({state}){
        window.scroll(0,0)
        state.errors = ''
        state.analysisList.forEach(analysis => {
            showResult(analysis)
        })
        showTotal()
    },
    calcAndShow({state}, value){
        let analysis = state.analysisList.filter( item => item.calorificValue == value)
        startAnalysis({
            numberOfPeople: state.numberOfPeople,
            A_MR: state.A_MR,
            A_OR: state.A_OR,
            A_A: state.A_A,
            calorificValueList: state.calorificValueList
        }, analysis[0])
        showResult(analysis[0])
        showTotal()
    }
}

const mutations = {
  update(state, values) {
    Object.keys(values).forEach(key => {
        Vue.set(state, key, values[key])
    })
  },
  updateObject(state, values) {
    Object.keys(values).forEach(key => {
      Object.keys(values[key]).forEach(childKey => {
        Vue.set(state[key], childKey, values[key][childKey])
      })
    })
  }
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations
})

// チャートリスト
export const charts = []

// 解析の計算処理
function startAnalysis (commCalcParam, state) {
    // 解析
    [state.result, state.plotData] = calculate.analysisAndPlot(commCalcParam, state)
}

// 解析の結果をチャートで表示する
function showResult(analysis) {
    // 表の表示
    const outputTable = document.getElementById('outputTable' + analysis.calorificValue)
    outputTable.textContent = null
    const [table, csvData] = makeTableElement(analysis.result)
    outputTable.appendChild( table )

    // CSVダウンロードリンク作成
    const csvLink = document.createElement('a')
    csvLink.setAttribute('id', 'download'+ analysis.calorificValue)
    csvLink.setAttribute('class', 'btn btn-outline-info btn_csv_dl')
    csvLink.setAttribute('role', 'button')
    csvLink.setAttribute('download', 'output.csv')
    csvLink.addEventListener('click', ((csvData) => {
        const content = '\uFEFF' + csvData.map(row => row.join(',')).join('\n')
        const blob = new Blob([ content ], { 'type' : 'text/csv' })

        if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(blob, 'test.txt')

            // msSaveOrOpenBlobの場合はファイルを保存せずに開ける
            window.navigator.msSaveOrOpenBlob(blob, 'test.txt')
        } else {
            document.getElementById('download' + analysis.calorificValue).href = window.URL.createObjectURL(blob)
        }
    }).bind(null, csvData, 0), false)
    csvLink.innerText = 'CSVダウンロード'
    outputTable.appendChild( csvLink )

    // グラフの表示
    const canvas = document.getElementById('outputChart' + analysis.calorificValue)
    plotFig(analysis.plotData, canvas)
}

// 出力用にstateからtable要素を作成する。ついでに2次元配列も作成する
function makeTableElement(data){
	const makeTd = (value, th = false) => {
		const td = document.createElement(th ? 'th' : 'td')
		td.innerHTML = value
		return td
	}
	const rowkeys = Object.keys(data)
	const headers = Object.keys( data[ rowkeys[0] ] )
	const table = document.createElement('table')
	table.setAttribute('class', 'table')
	const caption = document.createElement('caption')
  caption.innerHTML = "単位：MJ"
	table.appendChild(caption)
	const thead = document.createElement('thead')
	const theadTr = document.createElement('tr')
	theadTr.appendChild(makeTd('', true))
	headers.forEach(e => theadTr.appendChild(makeTd(e, true)))
	thead.appendChild(theadTr)
	table.appendChild(thead)
	const tbody = document.createElement('tbody')
	rowkeys.forEach(key => {
		const tbodyTr = document.createElement('tr')
		tbodyTr.appendChild(makeTd(key))
		headers.forEach(m => tbodyTr.appendChild(makeTd(data[key][m])))

		const calculateData = Object.values(data[key])
		if (sum(calculateData) == 0) {
			tbodyTr.setAttribute('style', 'display:none;')
		}
		thead.appendChild(tbodyTr)
	})
	table.appendChild(tbody)

	// 2次元配列を作成
	const csvData = [ ['', ...headers] ].concat( rowkeys.map(key => [key, ...headers.map(m => data[key][m])]) )
	return [table, csvData]
}

// 指定したキャンバスにグラフを描画する
function plotFig(data, canvas){
    // チャートが作成されている場合、チャートを削除する
    if (charts.length != 0) {
        charts.forEach( chart => {
            if(chart.canvas.id == canvas.id) {
                const index = charts.map(function(elem) { return elem.canvas.id}).indexOf(canvas.id)
                if (index > -1) {
                    charts.splice(index, 1)
                    chart.destroy()
                }
            }
        })
	}
	const ctx = canvas.getContext('2d')
	const datasets = data.map(d => ({
		label: d.label,
		data: d.data,
		borderWidth: 1,
		backgroundColor: d.color,
		borderColor: d.color,
	}))
	const chart = new Chart(ctx, {
		type: 'bar',
		data: {
			labels: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
			datasets: datasets,
		},
		options: {
			scales: {
				xAxes: [{
					stacked: true,
				}],
				yAxes: [{
					stacked: true,
					ticks: {
						beginAtZero:true
					},
          scaleLabel: {
              display: true,
              labelString: '一次エネルギー消費量（MJ)',
              fontColor: "#999",
              fontSize: 12,
          },
				}]
			},
			animation:false,
		}
	})

    charts.push(chart)
}

//エネルギー源ごとに用途分解した結果を合計する
function showTotal() {
  const total = {
    calorificValue:'合計'
  }
  total.result = calculate.getTableData(getZero(),getZero(),getZero(),getZero(),getZero(),getZero(),getZero(),getZero(),0)
  total.plotData = calculate.getFigData(getZero(),getZero(),getZero(),getZero(),getZero(),getZero(),getZero())
  state.analysisList.forEach(elem => {
    elem.plotData.forEach((key,value) => {
      for (let i = 0;i < key.data.length; i++) {
        total.plotData[value].data[i] += key.data[i]
      }
    })
    Object.keys(elem.result).forEach(key => {
      Object.keys(elem.result[key]).forEach(value => {
        total.result[key][value] += elem.result[key][value]
      })
    })
  })

  showResult(total)
}

//要素が0の配列を用意
function getZero() {
  return [0,0,0,0,0,0,0,0,0,0,0,0]
}