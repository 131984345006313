import {
    getAllUse,
    getIM_m,
    getMonthlyValue,
    getRatio,
    getStdConsumption,
    getTrueMonth,
    showMonthlyTable
} from './functions.js'
import {print, arrayParseInt, array2StringFloor} from './util.js'
import { sum } from 'mathjs'


/**
 * 4. 全体計算
 */

/**
 * 4.1 計算ロジック
 */
export function calculate(numberOfPeople, A_MR, A_OR, A_A, consumption,
    heatingUse, coolingUse, ventilationUse, ventilationCoeff, hotWaterSupplyUse, hotWaterSupplyCoeff,
    lightingUse, lightingCoeff, electricApplianceUse, electricApplianceCoeff, cookingUse, cookingCoeff,
    calorificValue, calorificValueSelect, comment
){

    const intermediateMonth = getIM_m(heatingUse, coolingUse, calorificValueSelect)

    if( comment ){
        print('中間月: ' + getTrueMonth(intermediateMonth))
    }

    const [E_V_ref_m, E_L_ref_m, E_AP_ref_m, E_CC_ref_m, E_HW_ref_m, E_exHC_ref_m]
        = getStdConsumption(numberOfPeople, A_MR, A_OR, A_A,
            ventilationCoeff, lightingCoeff, hotWaterSupplyCoeff, electricApplianceCoeff, cookingCoeff,
            ventilationUse, lightingUse, hotWaterSupplyUse, electricApplianceUse, cookingUse, calorificValueSelect)

    if( comment ){
        print('調整前の消費量')
        print('換気: ' + array2StringFloor(E_V_ref_m))
        print('照明: ' + array2StringFloor(E_L_ref_m))
        print('給湯: ' + array2StringFloor(E_HW_ref_m))
        print('家電: ' + array2StringFloor(E_AP_ref_m))
        print('調理: ' + array2StringFloor(E_CC_ref_m))
        print('全体: ' + array2StringFloor(E_exHC_ref_m))
    }

    let heating
    let cooling
    let hotWater
    let ventilation
    let lighting
    let electricAppliance
    let cooking

    const S_exHC_m = getMonthlyValue(getRatio(E_exHC_ref_m), intermediateMonth, consumption);

    [heating, cooling, ventilation, lighting, hotWater, electricAppliance, cooking] = getAllUse(heatingUse, coolingUse, calorificValue, consumption, S_exHC_m, E_V_ref_m, E_L_ref_m, E_AP_ref_m, E_CC_ref_m, E_HW_ref_m, E_exHC_ref_m)

    return [heating, cooling, ventilation, lighting, hotWater, electricAppliance, cooking]
}


/**
 * 4.2 入力値のコンバート
 */
export function calcAnalysis(commCalcParam, analysis, comment=false){
    return calculate(
        commCalcParam.numberOfPeople,
        commCalcParam.A_MR,
        commCalcParam.A_OR,
        commCalcParam.A_A,
        analysis.consumption.map(Number),
        analysis.heating,
        analysis.cooling,
        analysis.use.ventilation,
        analysis.coeff.ventilation,
        analysis.use.hotWaterSupply,
        analysis.coeff.hotWaterSupply,
        analysis.use.lighting,
        analysis.coeff.lighting,
        analysis.use.electricAppliance,
        analysis.coeff.electricAppliance,
        analysis.use.cooking,
        analysis.coeff.cooking,
        commCalcParam.calorificValueList[analysis.calorificValue],
        analysis.calorificValue,
        comment
    )
}


/**
 * 4.3 結果の描画
 */
// 1) グラフ
export function getFigData(heating, cooling, hotWaterSupply, ventilation, lighting, electricAppliance, cooking){
    return [
        { data: cooking, color: "#ffa645", label: "調理" },
        { data: electricAppliance, color: "#a3c78d", label: "家電" },
        { data: lighting, color: "#ffce56", label: "照明" },
        { data: ventilation, color: "#5e828f", label: "換気" },
        { data: hotWaterSupply, color: "#df5043", label: "給湯" },
        { data: cooling, color: "#a1cfe6", label: "冷房" },
        { data: heating, color: "#f9aba4", label: "暖房" },
    ]
}

// 2) 表
export function getTableData(heating, cooling, hotWaterSupply, ventilation, lighting, electricAppliance, cooking, consumption, calorificV){
    const total = heating.map((h, i) => h + cooling[i] + hotWaterSupply[i] + ventilation[i] + lighting[i] + electricAppliance[i] + cooking[i])
    const data = showMonthlyTable({
        '暖房': arrayParseInt(heating),
        '冷房': arrayParseInt(cooling),
        '換気': arrayParseInt(ventilation),
        '給湯': arrayParseInt(hotWaterSupply),
        '照明': arrayParseInt(lighting),
        '家電': arrayParseInt(electricAppliance),
        '調理': arrayParseInt(cooking),
        '分解後の合計': arrayParseInt(total),
        '分解前': arrayParseInt(consumption.map(c => c * calorificV))
    })
    Object.keys(data).forEach(key => {
        const calcurateData = Object.values(data[key])
        data[key].合計 = sum(calcurateData)
    })
    return data
}


/**
 * 4.4 最終的な関数
 *
 * 消費量を用途分解して(analysis関数)、グラフを描画し(getFigData関数)、描画に用いた値を表形式でプリント(getTableData関数)しています。
 */
export function analysisAndPlot(commCalcParam, analysis){
    const [heating, cooling, ventilation, lighting, hotWaterSupply, electricAppliance, cooking] = calcAnalysis(commCalcParam, analysis, false)
    const plotData = getFigData(heating, cooling, hotWaterSupply, ventilation, lighting, electricAppliance, cooking)
    const data = getTableData(heating, cooling, hotWaterSupply, ventilation, lighting, electricAppliance, cooking, analysis.consumption, commCalcParam.calorificValueList[analysis.calorificValue])
    return [data, plotData]
}